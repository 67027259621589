import Link from 'next/link';
import ImageWithFallback from '~/components/ImageWithFallback/ImageWithFallback';
import classes from './CollectionCard.module.scss';
import Chip from '~/components/Chip/Chip';
import _orderBy from 'lodash/orderBy';
import { useUsers } from '~/store/userContext/hooks';
import { useLang } from '~/store/languageContext/hooks';
import { iCollection, iLot } from '~/store/services/mito/mito.models';
import { generateStatics } from '~/store/services/mito/mito.utils';

interface iCollectionCard {
  className?: string;
  collection: iCollection;
  nonMobile?: boolean;
}

export default function CollectionCard({
  className,
  collection,
  nonMobile = false,
}: iCollectionCard) {
  const { t } = useLang();
  const { getUser } = useUsers();

  let collectionNfts = collection?.blockchain_nfts;

  const images = collectionNfts.map((nft, index) => {
    if (index > 3) return null;
    const { primary, secondary } = generateStatics(nft, 'thumb');

    return (
      <div key={index} className={classes.imageContainer}>
        <ImageWithFallback
          key={index}
          src={primary}
          fallbackSrc={secondary}
          alt={`nft-${nft?.title}`}
          layout="fill"
          objectFit="cover"
          objectPosition="center"
          collectionFilter
        />
      </div>
    );
  });

  const nftCount =
    collection?.blockchain_nfts?.length === 1
      ? `${collection?.blockchain_nfts?.length} ${t('common:artwork')}`
      : `${collection?.blockchain_nfts?.length} ${t('common:artworks')}`;

  return (
    <div className={className && className}>
      <Link href={`/collections/${encodeURIComponent(collection?.titleUrl)}`} passHref>
        <a>
          <div className={classes.CollectionCard}>
            <div
              className={`${classes.container} ${classes.top} ${
                nonMobile ? classes.nonMobile : ''
              }`}
            >
              <div className={`${classes.imageGrid} ${nonMobile ? classes.nonMobile : ''}`}>
                {images}
              </div>
            </div>

            <div className={`${classes.container} ${classes.bottom}`}>
              <Chip className={classes.chip} text={nftCount} />
              <h2>{collection?.title || 'Untitled'}</h2>
              <div className={classes.artistRow}>
                <p>{t('common:by')}</p>
                <h3>{getUser(collection?.creator).name || 'Anonymous'}</h3>
              </div>
            </div>
            <span className={classes.overlay} />
          </div>
        </a>
      </Link>
    </div>
  );
}
