import { iLot, iLotNFT, iNFTDetails } from "~/store/services/mito/mito.models";
import { IPFS_NODE, STATIC_HOST } from '~/constants';

export enum StaticsSizes {
     THUMB = "thumb",
     MID = "mid",
}

export enum StaticTypes {
     IMAGE = "image",
     VIDEO = "video",
}

export const generateStatics = (nft: Partial<iLotNFT> | iNFTDetails, size: string) => {

     const extension = (nft?.content_type === StaticTypes.VIDEO || nft?.contentType === StaticTypes.VIDEO ) ? "mp4" : "jpg";

     return {
          primary: `${STATIC_HOST}/${nft?.hex_original_nft_contract_id}_${size}.${extension}`,
          secondary: `${IPFS_NODE}/${nft?.ipfs_url}`
     }

}

